import Vue from 'vue'
import Router from 'vue-router'
import { store } from '@/store'
import { firebaseAnalytics, logEvent } from '@/firebase/firebase'
import web from './web'
import auth from './auth'
import account from './account'
import adminAccount from './admin-account'
import anonymous from './anonymous'

Vue.use(Router);

const routes =
[
    ...auth,
    ...account,
    ...adminAccount,
    ...anonymous,
    ...web
];

const router = new Router(
{
    mode: 'history',
    routes
});

router.beforeEach(async (to, from, next) =>
{
    const requiresAdmin = to.matched.some(record => record.meta.requiresAdmin);
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const requiresGuest = to.matched.some(record => record.meta.requiresGuest);
    const requiresAnonymous = to.matched.some(record => record.meta.requiresAnonymous);

    document.title = `Reflex Web Portal - ${to.meta.title}`;

    await store.dispatch('auth/ROUTE_PENDING');

    // console.log("from", from)
    // console.log("to", to)

    try
    {
        logEvent(firebaseAnalytics, 'screen_view',
        {
            app_name: 'Web Portal',
            screen_name: document.title,
            app_version: process.env.VUE_APP_VERSION
        });

        if (requiresAnonymous)
        {
            await store.dispatch('auth/ROUTE_COMPLETE');
            return next()
        }

        let currentUser = store.getters['auth/CURRENT_USER'];
        if (null == currentUser)
        {
            currentUser = await store.dispatch('auth/FETCH_AUTH');

            // If a profile is needed, send the user to the settings screen so they can agree to the TOS
            if (currentUser && currentUser.profileNeeded)
            {
                await store.dispatch('auth/ROUTE_COMPLETE');
                return next(
            {
                    name: 'Settings'
                })
            }
        }

        if (requiresGuest && currentUser)
        {
            await store.dispatch('auth/ROUTE_COMPLETE');

            if ('from' in to.query)
            {
                return next(
                {
                    path: to.query.from
                });
            }
            else
            {
                return next(
                {
                    name: 'Home'
                })
            }
        }
        // For users coming here from a link to deep link inside the web portal, redirect the user to the login so we can validate them
        else if (requiresAuth && !currentUser)
        {
            await store.dispatch('auth/ROUTE_COMPLETE');
            return next(
            {
                name: 'Login',
                query:
                {
                    from: to.fullPath
                }
            })
        }
        else if (requiresAdmin && (!currentUser || !currentUser.authorities || (currentUser.authorities[0].role !== 'ROLE_ORG_ADMIN' && currentUser.authorities[0].role !== 'ROLE_ADMIN')))
        {
            await store.dispatch('auth/ROUTE_COMPLETE');
            const loginPath = window.location.pathname;
            return next(
            {
                name: 'Login',
                query:
                {
                    from: loginPath
                }
            })
        }
        else
        {
            // If the user is coming from the home screen or no screen, hide the chat bot
            if ('Home' === from.name || !from.name)
            {
                if (document.getElementById('hubspot-messages-iframe-container'))
                {
                    document.getElementById('hubspot-messages-iframe-container').style.setProperty("display", "none", "important")
                }
            }
            // If the user is going to the login or home screen, show the chat bot
            if ('Login' === to.name || 'Home' === to.name)
            {
                if (document.getElementById('hubspot-messages-iframe-container'))
                {
                    document.getElementById('hubspot-messages-iframe-container').style.removeProperty("display")
                }
            }

            await store.dispatch('auth/ROUTE_COMPLETE');
            return next()
        }
    }
    catch (error)
    {
        console.log("Error in routing:", error);
        return next()
    }
});

export default router
