// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from '@/App'
import router from '@/router'
import { store } from '@/store'
import { firebaseApp, firebaseAnalytics, firebaseAuth, logEvent } from '@/firebase/firebase'
import axios from 'axios'
import vuetify from '@/plugins/vuetify'
import VueDebounce from 'vue-debounce'

Vue.config.productionTip = false;
Object.defineProperty(Vue.prototype, '$firebaseApp', {value: firebaseApp});
Object.defineProperty(Vue.prototype, '$firebaseAuth', {value: firebaseAuth});
Object.defineProperty(Vue.prototype, '$firebaseAnalytics', {value: firebaseAnalytics});
Vue.prototype.$logEvent = logEvent;
Object.defineProperty(Vue.prototype, '$axios', {value: axios});

Vue.use(VueDebounce,{
  defaultTime: '300ms',
  trim: true
})

new Vue(
{
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
